import { Route, BrowserRouter, Routes } from 'react-router-dom';
import CardPage from './pages/card';
import LevelCardPage from './pages/level-card';
import DiscoveryPage from './pages/discovery';
import MyLevels from './pages/my-levels';
import ExchangePage from './pages/exchange';
import './App.css';
import OrderPage from './pages/order';
import CardsPage from './pages/cards';
import LevelCardRecyclingPage from './pages/level-card-recycling';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/card/:hash' element={<CardPage />} />
          <Route path='/level_card/:hash' element={<LevelCardPage />} />
          <Route path='/level_card/:hash/discovery/:levelId' element={<DiscoveryPage />} />
          <Route path='/my_levels/:hash' element={<MyLevels />} />
          <Route path='/' element={<CardsPage />} />
          <Route path='/cards' element={<CardsPage />} />
          <Route path='/exchange' element={<ExchangePage />} />
          <Route path='/exchange/:hash' element={<ExchangePage />} />
          <Route path='/order/:hash' element={<OrderPage />} />

          <Route path='/level_card/:hash' element={<CardPage />} />
          <Route path='/level_card_recycling' element={<LevelCardRecyclingPage />} />

          <Route path='/' element={<CardsPage />} />
          <Route path='/level_cards' element={<CardsPage />} />
          <Route path='/level_exchange' element={<ExchangePage />} />
          <Route path='/level_exchange/:hash' element={<ExchangePage />} />
          <Route path='/level_order/:hash' element={<OrderPage />} />



        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ajax } from 'jquery';
import PacmanLoader from 'react-spinners/PacmanLoader';
import './index.less';

const OrderPage = () => {

    const [isLoading, setLoading] = useState(true)
    const [orderData, setOrderData] = useState<any>()
    const { hash } = useParams()

    useEffect(() => {
        const loadOrderData = async (hash: string) => {
            const result = await ajax({
                method: "GET",
                url: `/api/v1/backrooms_card/order/${hash}`,
            })
            setLoading(false)
            setOrderData(result)
        }
        loadOrderData(hash as string)
    }, [])

    return (
        <div className='page-wrap order-page'>
            {
                !isLoading &&
                <div className="order-page-content">
                    <div className="order-info-header">
                        <div className="order-title">
                            订单信息：
                        </div>
                        <div className='order-count'>
                            {orderData.length}个订单
                        </div>
                    </div>
                    <div className="orders-info">
                        {
                            orderData && orderData.map((orderInfo: any, index: number) => {
                                return <div className="order-info" key={"order" + index}>
                                    <div className={"status-bar" + (orderInfo.sent > 0 ? ' sent' : "")}>
                                        {orderInfo.sent > 0 ? ("已发货 [" + orderInfo.express + " " + orderInfo.trackingNumber + "]") : "等待发货"}
                                    </div>
                                    <div className="product">
                                        <img src="https://oss.usecubes.cn/backrooms/urcard.jpg" alt='URCARD' title='urcard' className="card-front-img" />
                                        <p className="product-name">Backrooms X档案 UR 卡包<br />包含5张卡牌<br />有几率出UR卡片</p>
                                    </div>
                                    <div className="post-info">
                                        <p className="name"><span className="title">收货人：</span><span className="info">{orderInfo.name}</span></p>
                                        <p className="phoneNumber"><span className="title">联系号码：</span><span className="info">{orderInfo.phoneNumber}</span></p>
                                        <p className="address"><span className="title">收货地址：</span><span className="info">{orderInfo.province} {orderInfo.city} {orderInfo.area} {orderInfo.address}</span></p>
                                    </div>
                                </div>
                            })
                        }
                        {
                            orderData.length === 0 &&
                            <p className="no-order">
                                未找到订单信息！
                            </p>
                        }

                    </div>
                    <div className="page-footer">
                        <span className="backrooms-title">
                            后室Backrooms X档案
                        </span>
                    </div>
                </div>
            }

            <PacmanLoader
                color='#ffffff'
                loading={isLoading}
                className='global-loading'
            />
        </div >
    );
}

export default OrderPage